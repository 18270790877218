import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Containers/container.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/components/Flex/flex.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/wrappers/Link/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/features/NavBar/NavBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/features/Sidebar/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["S"] */ "/vercel/path0/node_modules/swr/dist/index/config-client-Bi4rgVRk.mjs");
